<template>
    <span class="dou-badge" v-if="groups.length > 0">主表单</span>
    <el-form :model="form" :rules="rules" label-width="80px" ref="formRef">
        <el-form-item :label="item.display" v-show="item.show" :prop="item.name"
            v-for="item in entityObj.attr.filter(m => m.ignore != true)" v-bind:key="item.name">
            <el-input v-show="item.show" v-if="item.type == 'input'" v-model="form[item.name]"
                :placeholder="'请输入' + item.display"></el-input>
            <el-input v-if="item.type == 'number'" v-model="form[item.name]" type="number"></el-input>
            <el-input v-else-if="item.type == 'timespan'" v-model="form[item.name]" type="number"></el-input>
            <el-input v-else-if="item.type == 'textarea'" rows="3" v-model="form[item.name]" type="textarea" />
            <el-switch v-if="item.type == 'switch'" v-model="form[item.name]" v-on:change="switchValue(item.name)" />
            <el-date-picker v-if="item.type == 'date'" v-model="form[item.name]" type="date" :placeholder="item.display"
                :editable="false" style="width: 100%"
                :clearable="entityObj.entityName != 'Plan' && entityObj.entityName != 'Goal'" />
            <el-time-picker v-if="item.type == 'time'" v-model="form[item.name]" :placeholder="item.display"
                style="width: 100%" :editable="false" :format="'HH:mm'" />
            <el-date-picker v-if="item.type == 'datetime'" v-model="form[item.name]" type="datetime"
                placeholder="请选择时间" />
            <el-select v-if="item.type == 'selectData'" v-model="form[item.name]" :placeholder="'选择' + item.display">
                <el-option label="无" value="" />
                <el-option :label="itemSelect.key" :value="itemSelect.value" v-for="itemSelect in selectList[item.name]"
                    v-bind:key="itemSelect.key" />
            </el-select>
            <el-select v-if="item.type == 'enum'" v-model="form[item.name]" :placeholder="'选择' + item.display"
                v-on:change="changeValue(item.name)">
                <el-option label="无" value="" />
                <el-option :label="itemSelect.key" :value="itemSelect.value" v-for="itemSelect in enumArr[item.enum]"
                    :key="itemSelect.value" />
            </el-select>
        </el-form-item>
    </el-form>
    <div v-for="group in groups" v-bind:key="group.name">
        <div v-if="group.data.length == 0" style="margin: 20px 0;">
            <span class="dou-badge">{{ group.display }} &nbsp;</span>
            <svg class="dou-group-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                @click="groupAdd(group.name, index)">
                <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z">
                </path>
            </svg>
        </div>
        <div v-for="(groupItem, index) in group.data" v-bind:key="group.name + '_' + (index + 1)" v-else>
            <span class="dou-badge">{{ group.display + " " + (index + 1) }}</span>
            <svg class="dou-group-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                @click="groupAdd(group.name, index)">
                <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z">
                </path>
            </svg>
            <svg class="dou-group-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                @click="groupRemove(group.name, index)">
                <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM7 11H17V13H7V11Z">
                </path>
            </svg>
            <el-form label-width="80px" :ref="group.name + (index + 1)">
                <el-form-item :label="itemChild.display" :prop="itemChild.name" v-for="itemChild in group.items"
                    v-bind:key="group.name + '_' + itemChild.name + '_' + (index + 1)">
                    <el-input v-model="groupItem[itemChild.name]"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <span class="dialog-footer">
        <span class="dialog-footer">
            <el-button @click="closeWindow">取消</el-button>
            <el-button type="primary" @click="submitForm">
                提交
            </el-button>
        </span>
    </span>
</template>
<script>
import api from '../../../api/api.js';
import { mapMutations, mapState } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
    props: {
        entityName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "w"
        },
        id: {
            type: String
        }
    },
    data() {
        return {
            localId: null,//因为props的id不可直接修改
            entityObj: {},//实体
            form: {},//表单
            selectList: [],//下拉框组
            enumArr: {},//枚举数组
            rules: {},//验证规则
            formValues: [],
            groups: [],//用来存组
            query: ""//保存查询字符串
        }
    },
    created() {
        if (this.id != null && this.infoId != null) {
            console.error("GenericSave的id与全局参数infoid冲突");
            return;
        }
        //新打开时和info控件打开时需要初始化控件
        if ((this.id != null && this.id != "0") || (this.infoId != null && this.infoId != "0")) {
            this.localId = this.id;
        }
        var entity = this.douTable[this.entityName];
        var formValue = {};
        for (var i = 0; i < entity.attr.length; i++) {
            var element = entity.attr[i];
            //补全元素
            if (element.visable == null) {
                element.visable = true;
            }
            if (element.biz == null) {
                element.biz = [];
            }
            // 初始化 show 属性
            element.show = element.visable;
            if (element.default != null) {
                formValue[element.name] = element.default;
            }
            //处理group
            if (element.type == "group") {
                var tempGroup = {};
                tempGroup.name = element.name;
                tempGroup.display = element.display;
                tempGroup.items = element.items;
                tempGroup.data = [];
                this.groups.push(tempGroup);
                entity.attr.splice(i, 1);
                i -= 1;
            }
        }
        this.form = formValue;
        //个性化
        switch (entity.entityName) {
            case "Goal": {
                entity.attr.filter(m => m.name == "Frequency")[0].show = false;
                entity.attr.filter(m => m.name == "LoopType")[0].show = false;
                entity.attr.filter(m => m.name == "Limit")[0].show = false;
            } break;
        }
        this.entityObj = entity;
        this.dynamicUpdateRules();
        this.query = this.$route.query;
    },
    async mounted() {
        await this.initEnum();
        //初始化UI内容
        await this.initData();
        //初始化下拉框值
        if (this.id == null) {
            var defaultValueControls = this.entityObj.attr.filter(m => m.default != null);
            defaultValueControls.forEach(element => {
                this.form[element.name] = element.default;
                this.changeValue(element.name);
            });
        }
    },
    beforeUnmount() {
        console.log('GenericSave|beforeUnmount');
    },
    computed: {
        ...mapState('user', ['infoId', 'douTable'])
    },
    watch: {
        id() {
            this.initData();
        },
        infoId(newVal) {
            console.log("InfoId变更|" + newVal);
            this.localId = newVal;
            this.initData();
        }
    },
    components: {

    },
    methods: {
        ...mapMutations('user', ['SET_SAVE_STATUS', 'SET_REFRESH_LIST', 'SET_INFO_ID']),
        handleInputChange(values) {
            this.formValues = values;
            console.log(values);
        },
        //初始化方法
        async initEnum() {
            var entityAttr = this.entityObj.attr;
            //查询enum
            var enums = entityAttr.filter(m => m.type == "enum").map(m => m.enum);
            if (enums.length > 0) {
                //查询所需枚举
                const enumList = await api.GetGenericEnum(enums);
                // 处理登录成功的逻辑
                if (enumList.length > 0) {
                    enumList.forEach(m => {
                        this.enumArr[m.name] = m.data;
                    });
                }
            }
        },
        async initData() {
            console.log("Save初始化|" + this.localId);
            if (this.localId != null) {
                var resopnse = await api.GetGenericDataById(this.entityObj.entityName, this.localId);
                if (resopnse != null) {
                    var data = resopnse;
                    this.entityObj.attr.forEach(m => {
                        switch (m.type) {
                            case "date": {
                                if (data[m.name] != null) {
                                    data[m.name] = new Date(data[m.name]);
                                }
                            } break;
                            case "time": {
                                if (data[m.name] != null && data[m.name] != "") {
                                    data[m.name] = new Date("2000-01-01 " + data[m.name]);
                                }
                            } break;
                        }
                    });
                    this.form = data;
                    this.entityObj.attr.forEach(m => {
                        if (m.biz != null && m.biz.length > 0) {
                            if (m.biz.filter(n => n.value == this.form[m.name])) {
                                this.changeValue(m.name);
                            }
                        }
                    });
                    console.log(this.form);
                }
            } else {
                this.form = {};
                //个性化
                switch (this.entityObj.entityName) {
                    case "Plan":
                        {
                            this.form["StartTime"] = new Date();
                            this.form["EndTime"] = new Date();
                        } break;
                    case "Goal":
                        {
                            this.form["StartDate"] = new Date();
                            this.form["EndDate"] = new Date();
                        } break;
                    case "GoalExecution":
                        {
                            if (this.query.date != null) {
                                this.form["Date"] = new Date(this.query.date);
                            }
                        } break;
                }
            }
        },
        changeValue(name) {
            var attrs = this.entityObj.attr.filter(m => m.name == name);
            if (attrs.length > 0) {
                //暂时这么判断吧
                if (this.entityName == "goal") {
                    switch (name) {
                        case "GoalType": {
                            if (this.form[name] == "1") {
                                this.entityObj.attr.filter(m => m.name == "LoopType")[0].show = true;
                                this.entityObj.attr.filter(m => m.name == "Frequency")[0].show = true;
                                this.entityObj.attr.filter(m => m.name == "Limit")[0].show = false;
                            } else if (this.form[name] == "2" || this.form[name] == "3") {
                                this.entityObj.attr.filter(m => m.name == "LoopType")[0].show = false;
                                this.entityObj.attr.filter(m => m.name == "Frequency")[0].show = false;
                                this.entityObj.attr.filter(m => m.name == "Limit")[0].show = true;
                            } else {
                                this.entityObj.attr.filter(m => m.name == "LoopType")[0].show = false;
                                this.entityObj.attr.filter(m => m.name == "Frequency")[0].show = false;
                                this.entityObj.attr.filter(m => m.name == "Limit")[0].show = false;
                            }
                        } break;
                    }
                }
            }
        },
        switchValue(name) {
            var attrs = this.entityObj.attr.filter(m => m.name == name);
            if (attrs.length > 0) {
                //暂时这么判断吧
                // if (this.entityName == "plan") {
                // }
            }
        },
        dynamicUpdateRules() {
            var tempRules = {};
            this.entityObj.attr.forEach(element => {
                var tempRule = {
                    required: true,
                    trigger: "blur"
                };
                switch (element.type) {
                    case "input":
                    case "textarea":
                    case "date":
                    case "number":
                    case "time": {
                        if (element.required == true) {
                            tempRule.message = "请输入" + element.display;
                        }
                    } break;
                    case "selectData":
                    case "datetime":
                    case "enum":
                        {
                            if (element.required == true) {
                                tempRule.message = "请选择" + element.display;
                            }

                        } break;
                }
                if (element.show == true && element.required) {
                    tempRules[element.name] = [tempRule];
                }
            });
            this.rules = tempRules;
        },
        async submitForm() {
            console.log("保存表单");
            var self = this;
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    var tempObj = {};
                    Object.keys(self.form).forEach(m => {
                        tempObj[m] = self.form[m];
                    });
                    this.entityObj.attr.forEach(m => {
                        //不保存隐藏数据
                        if (m.show == false) {
                            if (tempObj[m.name] != null) {
                                delete tempObj[m.name];
                            }
                        }
                        else {
                            //处理特殊数据类型
                            switch (m.type) {
                                case "timespan": {
                                    tempObj[m.name] = tempObj[m.name] + ":00:00";
                                } break;
                                case "date": {
                                    if (tempObj[m.name] != null && tempObj[m.name] != "") {
                                        var monthStr = (tempObj[m.name].getMonth() + 1).toString();
                                        if (monthStr.length < 2) {
                                            monthStr = "0" + monthStr;
                                        }
                                        var dayStr = (tempObj[m.name].getDate()).toString();
                                        if (dayStr.length < 2) {
                                            dayStr = "0" + dayStr;
                                        }
                                        tempObj[m.name] = tempObj[m.name].getFullYear() + "-" + monthStr + "-" + dayStr;
                                    }
                                } break;
                                case "time": {
                                    if (tempObj[m.name] != null && tempObj[m.name] != "") {
                                        var hourStr = (tempObj[m.name].getHours()).toString();
                                        if (hourStr.length < 2) {
                                            hourStr = "0" + hourStr;
                                        }
                                        var minuteStr = (tempObj[m.name].getMinutes()).toString();
                                        if (minuteStr.length < 2) {
                                            minuteStr = "0" + minuteStr;
                                        }
                                        tempObj[m.name] = hourStr + ":" + minuteStr + ":00";
                                    }
                                } break;
                            }
                        }
                    });
                    this.groups.forEach(group => {
                        tempObj[group.name] = JSON.stringify(group.data);
                    });
                    //个性化
                    switch (this.entityObj.entityName) {
                        case "Goal": {
                            tempObj.PlanId = this.query.planid;
                        } break;
                    }
                    try {
                        if ((this.id != null && this.id != "0")) {
                            tempObj.Id = this.id;
                        }
                        if (this.query.pid != null) {
                            switch (this.entityObj.entityName) {
                                case "Prize":
                                case "RaffleHref": {
                                    tempObj.RaffleId = this.query.pid;
                                } break;
                                default: {
                                    tempObj.PId = this.query.pid;
                                } break;
                            }
                        }
                        try {
                            switch (this.entityObj.entityName) {
                                case "Plan": {
                                    await api.SavePlan(this.entityObj.entityName, tempObj);
                                } break;
                                case "Goal": {
                                    await api.SaveGoal(this.entityObj.entityName, tempObj);
                                } break;
                                case "GoalExecution": {
                                    await api.SaveGoalExec(tempObj);
                                } break;
                                case "PageFeature": {
                                    await api.SavePageFeature(tempObj);
                                } break;
                                default: {
                                    await api.SaveGenericData(this.entityObj.entityName, tempObj);
                                } break;
                            }
                            if (this.type == "w") {
                                this.SET_SAVE_STATUS(false);
                                this.SET_REFRESH_LIST(true);
                                if (this.infoId != null) {
                                    this.SET_INFO_ID(null);
                                }
                            } else if (this.type == "p") {
                                this.$emit("formResult", true);
                            }
                        } catch (error) {
                            // 处理登录失败的逻辑
                            ElMessage.error("保存失败！" + error.response.data);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        },
        closeWindow() {
            if (this.type == "w") {
                this.SET_SAVE_STATUS(false);
            } else {
                window.history.go(-1);
            }
        },
        groupAdd(name, index) {
            let groupData = this.groups.filter(m => m.name == name)[0];
            var newGroup = {};
            groupData.items.forEach(m => {
                newGroup[m.name] = "";
            });
            groupData.data.splice(index + 1, 0, newGroup);
        },
        groupRemove(name, index) {
            let groupData = this.groups.filter(m => m.name == name)[0];
            groupData.data.splice(index, 1);
        }
    }
}
</script>
<style scoped>
.el-button--text {
    margin-right: 15px;
}

.el-select {
    width: 300px;
}

.el-input {
    width: 300px;
}

.dialog-footer button:first-child {
    margin-right: 10px;
}

.dou-group-svg {
    width: 16px;
    vertical-align: sub;
}
</style>