<template>
    <el-row v-if="false">
        <el-col :span="24">
            <GlobalBreadcrumb :name="''">
                <svg width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M16 13L22.9641 17.0622L19.9913 17.9129L22.116 21.5933L20.384 22.5933L18.2592 18.9129L16.0359 21.0622L16 13ZM14 6H16V8H21C21.5523 8 22 8.44772 22 9V13H20V10H10V20H14V22H9C8.44772 22 8 21.5523 8 21V16H6V14H8V9C8 8.44772 8.44772 8 9 8H14V6ZM4 14V16H2V14H4ZM4 10V12H2V10H4ZM4 6V8H2V6H4ZM4 2V4H2V2H4ZM8 2V4H6V2H8ZM12 2V4H10V2H12ZM16 2V4H14V2H16Z">
                    </path>
                </svg>
                <span>布局</span>
                <span style="margin:0 3px">|</span>
                <svg width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM12 3.311L4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z">
                    </path>
                </svg>
                <span>设置</span>
            </GlobalBreadcrumb>
        </el-col>
    </el-row>
    <el-row v-if="false" style="margin:10px 0">
        <el-col>
            <div>多云24℃</div>
            <div>青岛市晴22℃-26℃</div>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0">
        <el-col :span="24"> <span class="dou-badge">今日账单</span></el-col>
        <el-col :span="12">
            <div class="grid-content ep-bg-purple d-box" @click="goUrl('BillDayReport', 'type=2')">
                <b>{{ bill.inAmount }}</b>
                <span style="font-size: 14px;">本日收入</span>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="grid-content ep-bg-purple d-box" @click="goUrl('BillDayReport', 'type=1')">
                <b>{{ bill.outAmount }}</b>
                <span style="font-size: 14px;">本日支出</span>
            </div>
        </el-col>
    </el-row>
    <el-row v-if="false" style="margin:10px 0">
        <el-col :span="24">
            <div>
                <span class="dou-badge">今日提醒</span>
            </div>
            <div style="font-size: 14px;">
                猫咪还有 <b>200</b> 天过生日。
            </div>
        </el-col>
    </el-row>
    <el-row v-if="false" style="margin:10px 0">
        <el-col :span="24">
            <div>
                <span class="dou-badge">天气信息</span>
            </div>
            <div style="font-size: 14px;">
                山东省青岛市 晴 25℃-35℃
            </div>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <span class="dou-badge">日志</span>
        </el-col>
        <el-col :span="24">
            <div style="font-size: 14px;color:#409eff;display: flex" @click="goUrl('DiarySave')">
                <svg v-if="saveDiary == false" xmlns="http://www.w3.org/2000/svg" width=20 viewBox="0 0 24 24"
                    fill="rgba(64,158,255,1)">
                    <path
                        d="M6.93912 14.0328C6.7072 14.6563 6.51032 15.2331 6.33421 15.8155C7.29345 15.1189 8.43544 14.6767 9.75193 14.5121C12.2652 14.198 14.4976 12.5385 15.6279 10.4537L14.1721 8.99888L15.5848 7.58417C15.9185 7.25004 16.2521 6.91614 16.5858 6.58248C17.0151 6.15312 17.5 5.35849 18.0129 4.2149C12.4197 5.08182 8.99484 8.50647 6.93912 14.0328ZM17 8.99739L18 9.99669C17 12.9967 14 15.9967 10 16.4967C7.33146 16.8303 5.66421 18.6636 4.99824 21.9967H3C4 15.9967 6 1.99669 21 1.99669C20.0009 4.99402 19.0018 6.99313 18.0027 7.99402C17.6662 8.33049 17.3331 8.66382 17 8.99739Z">
                    </path>
                </svg>
                <span v-if="saveDiary == false">去填写</span>
                <span v-else>已填写</span>
            </div>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <span class="dou-badge">当前执行</span>
            <el-card style="margin: 10px 0">
                <div v-for="(item, index) in nowData" v-bind:key="item.id" style="font-size: 14px;"
                    @click="showDateExecInfo(item.id)">
                    <div style="display: flex;justify-items: center;">
                        <span class="dou-badge">{{ item.startTime }} - {{ item.endTime }}</span>
                    </div>
                    <div style="margin: 3px 0;">
                        <el-tag type="primary" v-if="item.status == 0">{{ item.statusName }}</el-tag>
                        <el-tag type="success" v-else-if="item.status == 1 && item.goalType != 3">{{ item.statusName
                            }}</el-tag>
                        <el-tag type="danger"
                            v-else-if="(item.status == 1 && item.goalType == 3) || item.status == 2">{{
                                item.statusName }}</el-tag>
                        <el-tag type="info" v-else>{{ item.statusName }}</el-tag>
                        <span v-html="' ' + item.content.split('\n').join('<br/>')"></span>
                    </div>
                    <div style="margin: 3px 0;" v-if="item.goal != null">
                        <el-tag type="warning">目标</el-tag><span style="margin-left: 3px;">{{ item.goal }}</span>
                    </div>
                    <div style="margin: 3px 0;" v-if="item.memo != null">
                        <el-tag type="warning">备注</el-tag><span style="margin-left: 3px;">{{ item.memo }}</span>
                    </div>
                    <hr v-if="index != nowData.length - 1" />
                </div>
                <div v-if="nowData.length == 0"><span class="dou-badge">暂无内容</span></div>
            </el-card>
        </el-col>
    </el-row>
    <el-row style="margin:10px 0">
        <el-col :span="24">
            <div>
                <span class="dou-badge">日程待做</span>
            </div>
            <div>
                <el-tabs class="demo-tabs" v-model="todo.active" @tab-change="GetTodoList" v-show="false">
                    <el-tab-pane label="今天" name="today">
                        <DouTimeline :formData="todo.today" :name="今日待做" :entityName="todo.entity"></DouTimeline>
                    </el-tab-pane>
                    <el-tab-pane label="近七天" name="seven">
                        <DouTimeline :formData="todo.seven" :name="今日待做" :entityName="todo.entity"></DouTimeline>
                    </el-tab-pane>
                    <el-tab-pane label="以后" name="after">
                        <DouTimeline :formData="todo.after" :name="今日待做" :entityName="todo.entity"></DouTimeline>
                    </el-tab-pane>
                    <el-tab-pane label="已完成" name="completed">
                        <DouTimeline :formData="todo.completed" :name="今日待做" :entityName="todo.entity">
                        </DouTimeline>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div style="overflow-x: auto;">
                <el-calendar v-model="calendarChoosedDate">
                    <template #date-cell="{ data }">
                        <div>
                            <div>{{ data.date.getDate() }}</div>
                            <div v-if="calendarData.filter(m => m.date == data.day).length > 0">
                                <span
                                    style="font-size: 12px;word-break:keep-all;text-wrap:nowrap;overflow:hidden;display:block;white-space: nowrap;"
                                    v-bind:key="item.id"
                                    v-for="item in calendarData.filter(m => m.date == data.day)[0].exec">
                                    {{ item.name }}</span>
                            </div>
                        </div>
                    </template>
                </el-calendar>
            </div>
        </el-col>
    </el-row>
    <el-dialog v-model="showDateInfoDialog"
        :title="calendarChoosedDate == '' ? '' : calendarChoosedDate.toLocaleDateString() + '执行列表'" width="90%">
        <div style="color: #409eff;padding-bottom: 10px;" @click="addNormalExec">+点击添加一般执行</div>
        <el-timeline>
            <el-timeline-item v-for="(line, index) in dateInfo" :key="index" :type="line.type" :color="line.color"
                :size="line.size" :hollow="line.hollow" :hide-timestamp="line.hideTimestamp" :timestamp="line.timestamp"
                placement="top">
                <div style="margin: 1px 0;" v-for="item in line.items" :key="item" @click="showDateExecInfo(item.id)">
                    <div>
                        <el-tag type="primary" v-if="item.status == 0">{{ item.statusName }}</el-tag>
                        <el-tag type="success" v-else-if="item.status == 1 && item.goalType != 3">{{ item.statusName
                            }}</el-tag>
                        <el-tag type="danger"
                            v-else-if="(item.status == 1 && item.goalType == 3) || item.status == 2">{{
                                item.statusName }}</el-tag>
                        <el-tag type="info" v-else>{{ item.statusName }}</el-tag>
                        <span v-html="' ' + item.content.split('\n').join('<br/>')"></span>
                    </div>
                    <div v-if="item.goal != null" style="display: flex;justify-items: center;padding: 1px 0;">
                        <el-tag type="warning">目标</el-tag><span style="margin-left: 3px;">{{ item.goal }}</span>
                    </div>
                    <div v-if="item.memo != null" style="display: flex;justify-items: center;padding: 1px 0;">
                        <el-tag type="warning">备注</el-tag><span style="margin-left: 3px;">{{ item.memo }}</span>
                    </div>
                </div>
            </el-timeline-item>
        </el-timeline>
        <div v-if="dateInfo.length == 0"><span class="dou-badge">暂无内容</span></div>
    </el-dialog>
</template>
<script>
import api from '../../api/api.js'; // 替换成你的实际路径
import DouTimeline from '../Sub/DouTimeline.vue';
import { ElMessage } from 'element-plus';
export default {
    props: {

    },
    data() {
        return {
            todo: {
                active: "today",
                entity: "Todo"
            },
            saveDiary: false,
            calendarData: [{ date: "", exec: [] }],
            calendarChoosedDate: "",
            showDateInfoDialog: false,//是否展示详情
            dateInfo: [],
            nowData: [],
            bill: {},//账单相关
            isDragging: null,
            insertIndex: null,
            items: [
                { name: 'Item 1' },
                { name: 'Item 2' },
                { name: 'Item 3' },
                { name: 'Item 4' },
            ]
        }
    },
    created() {

    },
    mounted() {
        //this.GetTodoList("today");
        this.GetHome();
    },
    watch: {
        calendarChoosedDate(newVal, oldVal) {
            if (oldVal != "") {
                if (newVal.getFullYear() != oldVal.getFullYear() || newVal.getMonth() != oldVal.getMonth()) {
                    this.GetCalendar(newVal.toLocaleDateString());
                } else {
                    this.showDateInfo(newVal);
                }
            } else {
                var nowMonth = new Date().getMonth();
                if (newVal.getMonth() == nowMonth && oldVal == "") {
                    this.showDateInfo(newVal);
                } else {
                    this.GetCalendar(newVal.toLocaleDateString());
                }
            }
        }
    },
    components: {
        DouTimeline
    },
    methods: {
        async GetTodoList(name) {
            try {
                const response = await api.GetTodoList(name);
                //拼接数据源
                var tempArr = [];
                response.todo.forEach(element => {
                    var tempData = {};
                    tempData.content = element.name;
                    tempData.timestamp = element.date;
                    if (element.id != null) {
                        tempData.id = element.id;
                    }
                    if (element.date != null && element.date != "") {
                        tempData.color = '#0bbd87';
                        tempData.size = 'large';
                    } else {
                        tempData.hideTimestamp = true;
                    }
                    tempArr.push(tempData);
                });
                this.todo[name] = tempArr;
                this.saveDiary = response.diary;
            } catch (error) {
                // 处理登录失败的逻辑
                console.error('Login failed:', error);
            }
        },
        async GetHome() {
            try {
                const response = await api.GetHome();
                //拼接数据源
                this.calendarData = response.calendar;
                this.nowData = response.now;
                this.bill = response.bill;
                this.saveDiary = response.diary;
            } catch (error) {
                // 处理登录失败的逻辑
                ElMessage.error("初始化数据失败！" + error.response.data);
            }
        },
        async GetCalendar(date) {
            try {
                const response = await api.GetCalendar(date);
                //拼接数据源
                this.calendarData = response;
            } catch (error) {
                // 处理登录失败的逻辑
                ElMessage.error("获取日历数据失败！" + error.response.data);
            }
        },
        goUrl(url, param1) {
            var paramObj = {};
            if (param1 != null) {
                var param1Arr = param1.split('=');
                paramObj[param1Arr[0]] = param1Arr[1];
            }
            if (Object.keys(paramObj).length == 0) {
                this.$router.push({ name: url });
            } else {
                this.$router.push({ name: url, query: paramObj });
            }
        },
        showDateInfo(newVal) {
            var newValDate = newVal.toLocaleDateString();
            let now = new Date();
            var dateInfo = this.calendarData.filter(m => new Date(m.date).toLocaleDateString() == newValDate);

            if (dateInfo.length > 0) {
                let groupedData = new Map();

                dateInfo[0].exec.forEach(item => {
                    // 获取时间戳
                    let timestamp = (item.startTime != null && item.endTime != null)
                        ? item.startTime + " - " + item.endTime
                        : "全天";

                    if (!groupedData.has(timestamp)) {
                        // 处理全天的颜色逻辑
                        let color = "default";
                        if (timestamp === "全天") {
                            let startOfDay = new Date(dateInfo[0].date);
                            let endOfDay = new Date(dateInfo[0].date);
                            endOfDay.setHours(23, 59, 59);
                            if (now >= startOfDay && now <= endOfDay) {
                                color = "green";
                            }
                        } else {
                            // 处理具体时间段的颜色逻辑
                            let tempItemStartDate = new Date(dateInfo[0].date + " " + item.startTime);
                            let tempItemEndDate = new Date(dateInfo[0].date + " " + item.endTime);
                            if (tempItemEndDate < tempItemStartDate) {
                                tempItemEndDate.setDate(tempItemEndDate.getDate() + 1);
                            }
                            if (now >= tempItemStartDate && now <= tempItemEndDate) {
                                color = "green";
                            }
                        }

                        // 初始化分组
                        groupedData.set(timestamp, {
                            timestamp: timestamp,
                            color: color,
                            items: []
                        });
                    }

                    // 添加执行项到对应分组
                    groupedData.get(timestamp).items.push({
                        id: item.id,
                        goal: item.goal,
                        goalType: item.goalType,
                        content: item.name,
                        status: item.status,
                        statusName: item.statusName,
                        memo: item.memo
                    });
                });

                // 转换为数组
                this.dateInfo = Array.from(groupedData.values());
            } else {
                this.dateInfo = [];
            }
            this.showDateInfoDialog = true;
        },
        showDateExecInfo(id) {
            this.$router.push('/pi/goalexecution/' + id);
        },
        addNormalExec() {
            var dateStr = this.calendarChoosedDate.toLocaleDateString();
            this.$router.push('/ps/goalexecution/0?date=' + dateStr);
        }
        // addNormalExec() {
        //     var dateStr = this.calendarChoosedDate.toLocaleDateString();
        //     this.$router.push({ name: 'GoalExecutionSave', params: { id: '0' }, query: { date: dateStr } });
        // }
    }
}
</script>
<style>
.el-timeline {
    padding-inline-start: 0px;
}

.demo-tabs>.el-tabs__content {
    margin: 0px;
}

.el-timeline-item {
    padding-bottom: 20px;
}

.el-calendar-day {
    overflow: hidden;
}

hr {
    border-top: #dcdfe6;
}

/* 拖动 */
.drag-item.dragging {
    background-color: #d0e1ff;
    /* 高亮颜色 */
}

.drag-insert-line {
    height: 2px;
    background-color: #007bff;
    /* 插入线的颜色 */
    position: absolute;
    width: 100%;
}
</style>
