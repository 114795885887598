<template>
    <div>
      <span class="dou-badge">目标执行表单</span>
      <el-form :model="form" :rules="rules" label-width="80px" ref="formRef">
        <!-- 日期 -->
        <el-form-item label="日期" prop="Date">
          <el-date-picker
            v-model="form.Date"
            type="date"
            placeholder="请选择日期"
            style="width: 100%"
          />
        </el-form-item>
  
        <!-- 全天开关 -->
        <el-form-item label="是否全天">
          <el-switch v-model="form.IsAllDay" @change="toggleAllDay"></el-switch>
        </el-form-item>
  
        <!-- 开始时刻 -->
        <el-form-item label="开始时刻" prop="StartTime" v-show="!form.IsAllDay">
          <el-time-picker
            v-model="form.StartTime"
            placeholder="请选择开始时间"
            :format="'HH:mm'"
            style="width: 100%"
          />
        </el-form-item>
  
        <!-- 结束时刻 -->
        <el-form-item label="结束时刻" prop="EndTime" v-show="!form.IsAllDay">
          <el-time-picker
            v-model="form.EndTime"
            placeholder="请选择结束时间"
            :format="'HH:mm'"
            style="width: 100%"
          />
        </el-form-item>
  
        <!-- 内容 -->
        <el-form-item label="内容" prop="Content">
          <el-input
            type="textarea"
            v-model="form.Content"
            :placeholder="'请输入内容'"
            rows="3"
          />
        </el-form-item>
  
        <!-- 操作按钮 -->
        <div class="dialog-footer">
          <el-button @click="closeWindow">取消</el-button>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </div>
      </el-form>
    </div>
  </template>
  
  <script setup>
  import { ref, reactive, onMounted,defineProps } from "vue";
  import { ElMessage } from "element-plus";
  import api from "../../../api/api";
  
  // 接收父组件传入的 props
  const props = defineProps({
    id: {
      type: String,
      default: null,
    },
  });
  
  // 表单数据
  const form = reactive({
    Date: null,
    IsAllDay: false, // 是否全天
    StartTime: null,
    EndTime: null,
    Content: "",
  });
  
  // 验证规则
  const rules = {
    Date: [{ required: true, message: "请选择日期", trigger: "blur" }],
    StartTime: [
      {
        required: !form.IsAllDay,
        message: "请选择开始时间",
        trigger: "blur",
      },
    ],
    EndTime: [
      {
        required: !form.IsAllDay,
        message: "请选择结束时间",
        trigger: "blur",
      },
    ],
    Content: [{ required: true, message: "请输入内容", trigger: "blur" }],
  };
  
  // 表单引用
  const formRef = ref(null);
  
  // 初始化数据
  const initData = async () => {
    if (props.id) {
      try {
        const response = await api.GetGenericDataById("GoalExecution", props.id);
        Object.assign(form, response);
      } catch (error) {
        ElMessage.error("数据加载失败！");
      }
    }
  };
  
  // 全天切换逻辑
  const toggleAllDay = () => {
    if (form.IsAllDay) {
      form.StartTime = null;
      form.EndTime = null;
    }
  };
  
  // 提交表单
  const submitForm = () => {
    formRef.value.validate(async (valid) => {
      if (valid) {
        try {
          const payload = { ...form };
  
          // 格式化时间
          if (!form.IsAllDay) {
            payload.StartTime = formatTime(payload.StartTime);
            payload.EndTime = formatTime(payload.EndTime);
          }
  
          // 保存数据
          if (props.id) {
            payload.Id = props.id;
          }
          await api.SaveGenericData("GoalExecution", payload);
          ElMessage.success("保存成功！");
        } catch (error) {
          ElMessage.error("保存失败！");
        }
      } else {
        ElMessage.warning("请完善表单！");
      }
    });
  };
  
  // 时间格式化工具
  const formatTime = (time) => {
    if (!time) return null;
    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}:00`;
  };
  
  // 关闭窗口
  const closeWindow = () => {
    window.history.go(-1);
  };
  
  // 初始化加载
  onMounted(() => {
    initData();
  });
  </script>
  
  <style scoped>
  .dou-badge {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .dialog-footer {
    margin-top: 20px;
    text-align: right;
  }
  </style>
  